#projects-container {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-template-rows: auto;
}

.project {
    cursor: pointer;
    padding: 0.25em;

    transform: scale(1);
    transition: 0.1s ease-in;
}

.project:hover {
    transform: scale(1.1);
}

.project:hover h4 {
    opacity: 1;
}

.project h4 {
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0;
    font-size: 3vw;
}

.project img {
    border-radius: 1em;
    max-width: 100%;
    height: auto;
}

@media screen and (min-width: 800px) {
    .project h4 {
        font-size: 24px;
    }
}