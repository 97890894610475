.App {
  font-family: 'Open Sans Condensed', sans-serif;
  text-align: center;
  font-size: 20px;
  transition: top .8s cubic-bezier(0.77, 0, 0.175, 1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Staatliches', cursive;
  font-weight: normal;
}

#sticky-bar {
  position: fixed;
  background-color: #eee;
  border-right: 0.1em solid #eee;
  border-radius: 0 0 1em 0;
  padding: 0.25em;
}

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

#sticky-bar img {
  max-width: 2em;
  height: auto;
  margin: 0.25em;
}

header,
footer {
    padding: 1em;
}

header h1 {
  margin: 0;
}

header img {
    border-radius: 1em;
    width: 5em;
}

#root[theme=light] {
  background-color: #fff;
  color: #222;
}

#root[theme=dark] {
  background-color: #222;
  color: #ddd;
}