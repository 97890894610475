body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
    background-color: inherit;
    border-color: inherit;
    color: inherit;
    margin: 0.5em;
}

.react-hint {
	padding: 5px;
	position: absolute;
	z-index: 9999;
	cursor: default;
	-webkit-animation: 0.5s fadeIn;
	        animation: 0.5s fadeIn;
}

.react-hint__content {
	padding: 10px;
	border-radius: 5px;
	background: inherit;
	color: inherit;
}

.react-hint:after {
	content: '';
	width: 0;
	height: 0;
	margin: auto;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 5px solid transparent;
}

.react-hint--top:after {
	top: auto;
	border-bottom: none;
	border-top-color: #222;
}

.react-hint--left:after {
	left: auto;
	border-right: none;
	border-left-color: #222;
}

.react-hint--right:after {
	right: auto;
	border-left: none;
	border-right-color: #222;
}

.react-hint--bottom:after {
	bottom: auto;
	border-top: none;
	border-bottom-color: #222;
}

#root[theme=light] .react-hint__content {
    color: #fff;
	background: #222;
}

#root[theme=dark] .react-hint__content {
    color: #222;
	background: #ddd;
}

#root[theme=dark] .react-hint--top:after {
    border-top-color: #ddd;
}

#root[theme=dark] .react-hint--bottom:after {
    border-bottom-color: #ddd;
}

#root[theme=dark] .react-hint--left:after {
    border-left-color: #ddd;
}

#root[theme=dark] .react-hint--right:after {
    border-right-color: #ddd;
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
#projects-container {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-template-rows: auto;
}

.project {
    cursor: pointer;
    padding: 0.25em;

    -webkit-transform: scale(1);

            transform: scale(1);
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
}

.project:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.project:hover h4 {
    opacity: 1;
}

.project h4 {
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0;
    font-size: 3vw;
}

.project img {
    border-radius: 1em;
    max-width: 100%;
    height: auto;
}

@media screen and (min-width: 800px) {
    .project h4 {
        font-size: 24px;
    }
}
.panel {
    display: block;
    text-align: left;
    width: 80%;
    margin: 0 auto;
}
.App {
  font-family: 'Open Sans Condensed', sans-serif;
  text-align: center;
  font-size: 20px;
  -webkit-transition: top .8s cubic-bezier(0.77, 0, 0.175, 1);
  transition: top .8s cubic-bezier(0.77, 0, 0.175, 1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Staatliches', cursive;
  font-weight: normal;
}

#sticky-bar {
  position: fixed;
  background-color: #eee;
  border-right: 0.1em solid #eee;
  border-radius: 0 0 1em 0;
  padding: 0.25em;
}

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

#sticky-bar img {
  max-width: 2em;
  height: auto;
  margin: 0.25em;
}

header,
footer {
    padding: 1em;
}

header h1 {
  margin: 0;
}

header img {
    border-radius: 1em;
    width: 5em;
}

#root[theme=light] {
  background-color: #fff;
  color: #222;
}

#root[theme=dark] {
  background-color: #222;
  color: #ddd;
}
